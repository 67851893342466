"use client";
import React from "react";

const LOCAL_STORAGE_MODAL_KEY = "isPatologyModalOpen";

const ButtonDoctor: React.FC = () => {
  const openModal = () => {
    localStorage.setItem(LOCAL_STORAGE_MODAL_KEY, "true");
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <button
      className="rounded-xl bg-brand-dark px-4 py-2 text-white"
      onClick={openModal}
    >
      Falar com médico
    </button>
  );
};

export default ButtonDoctor;
