"use client";
import React, { useState, useCallback } from "react";
import Image from "next/image";

const PlayerDoctor: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
    document.body.classList.add("no-scroll");
    document.addEventListener("click", handleClickOutside);
  };

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    document.body.classList.remove("no-scroll");
    document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const modal = document.querySelector(".modal-container");
      if (modal && !modal.contains(event.target as Node)) {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  return (
    <>
      <div className="absolute z-20 flex h-16 w-16 items-center justify-center rounded-full bg-brand-light pl-1 animate-pulse cursor-pointer">
        <Image
          src="/image/play-icon.svg"
          alt="Thumbnail"
          width={26}
          height={30}
          onClick={handleImageClick}
        />
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <div className="modal-container relative h-[50%] w-[90%] bg-transparent md:h-[450px] md:w-[800px]">
            <button
              type="button"
              className="absolute right-2 top-2 h-8 w-8 rounded-full bg-white text-black"
              onClick={handleCloseModal}
            >
              X
            </button>
            <iframe
              className="h-full w-full rounded-2xl"
              src="https://player.vimeo.com/video/1010392256?autoplay=1&loop=1&autopause=0&player_id=0&app_id=58479"
              allow="fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="Mitos Verdades 2"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerDoctor;
