import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedHamburgerButton"] */ "/vercel/path0/src/app/_components/animatedHamburguerButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/ButtonDoctor/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/ConsumptionSelector/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/CTAButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/DoctorModal/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/patologyForm/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/PlayerDoctor/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Reveal"] */ "/vercel/path0/src/app/_components/revealPageItems/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/sectionsHomePage/custom.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/VideoGallery/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/vercel/path0/src/app/preload-resources.tsx");
