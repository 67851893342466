"use client";

import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Image from "next/image";

const options = [
  { id: "óleo", label: "Óleos", icon: "/image/secao1/oil-icon.svg" },
  { id: "gummy", label: "Gummy", icon: "/image/secao1/gummy-icon.svg" },
  { id: "creme", label: "Cremes", icon: "/image/secao1/cream-icon.svg" },
];

const consumptionInfo = {
  óleo: "Os óleos de Cannabis são uma forma popular de consumo, permitindo uma dosagem precisa e um método de uso fácil. Eles são ideais para quem busca um tratamento consistente e controlado.",
  gummy:
    "As gomas de Cannabis são uma alternativa prática e saborosa. Elas proporcionam uma maneira discreta e conveniente de consumir Cannabis Medicinal, adequadas para diferentes estilos de vida.",
  creme:
    "Os cremes de Cannabis Medicinal são aplicados diretamente na pele, sendo eficazes no tratamento de dores locais e inflamações. Eles oferecem uma abordagem direcionada e são fáceis de usar em qualquer momento do dia.",
};

const ConsumptionSelector = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  // Recupera a opção salva no localStorage ao carregar a página
  useEffect(() => {
    const storedOption = localStorage.getItem("selectedConsumptionMethod");
    if (
      storedOption &&
      consumptionInfo[storedOption as keyof typeof consumptionInfo]
    ) {
      setSelectedOption(storedOption);
    }
  }, []);

  // Salva no localStorage quando o usuário clica em uma opção
  const handleSelect = (option: string) => {
    setSelectedOption(option);
    localStorage.setItem("selectedConsumptionMethod", option);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {/* Opções de seleção */}
      <div className="flex items-start w-full flex-row gap-8">
        {options.map((option) => (
          <motion.div
            key={option.id}
            className={`flex cursor-pointer flex-col items-center justify-center text-center ${
              selectedOption === option.id ? "text-[#76C982]" : "text-[#dbdbdb]"
            }`}
            onClick={() => handleSelect(option.id)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Image
              src={option.icon}
              alt={`${option.label} icon`}
              width={64}
              height={64}
              className="z-20 pb-4"
            />
            <span className="text-base">{option.label}</span>
          </motion.div>
        ))}
      </div>

      {/* Texto correspondente à opção selecionada */}
      {selectedOption && (
        <motion.p
          className="z-20 pt-20 text-base leading-relaxed text-white"
          key={selectedOption}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {consumptionInfo[selectedOption as keyof typeof consumptionInfo]}
        </motion.p>
      )}
    </div>
  );
};

export default ConsumptionSelector;
