"use client";

import { useState } from "react";
import Link from "next/link";

interface AnimatedHamburgerButtonProps {}

export const AnimatedHamburgerButton: React.FC<
  AnimatedHamburgerButtonProps
> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const navItems = [
    { label: "Como funciona", href: "/#como-funciona" },
    { label: "Galeria", href: "/galeria" },
    { label: "Depoimentos", href: "/#depoimentos" },
    // { label: "Benefícios", href: "/#beneficios" },
    { label: "Sobre nós", href: "/sobre-nos" },
    { label: "Blog", href: "/blog" },
    { label: "Médicos Click", href: "/seja-parceiro" },
    { label: "FAQ", href: "/duvidas-frequentes" },
  ];

  return (
    <>
      {/* Botão Hamburguer */}
      <button
        onClick={toggleMenu}
        className="relative h-6 w-6 lg:hidden"
        aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
      >
        {/* Top Bar */}
        <span
          className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-transform duration-500 ${
            isOpen ? "top-1/2 rotate-45" : "top-1/4 rotate-0"
          }`}
        />
        {/* Middle Bar */}
        <span
          className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-opacity duration-500 ${
            isOpen ? "opacity-0" : "top-1/2 opacity-100"
          }`}
        />
        {/* Bottom Bar */}
        <span
          className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-transform duration-500 ${
            isOpen ? "top-1/2 -rotate-45" : "top-3/4 rotate-0"
          }`}
        />
      </button>

      {/* Menu Drawer */}
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50"
          onClick={toggleMenu}
        >
          <div
            className="fixed right-0 top-0 z-50 h-full w-64 bg-white p-6 shadow-lg transform transition-transform duration-300 ease-in-out"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Botão de Fechar */}
            <div className="mb-6 flex justify-end">
              <button onClick={toggleMenu} className="text-gray-700">
                ✖
              </button>
            </div>

            {/* Navegação */}
            <nav>
              <ul className="space-y-4">
                {navItems.map(({ label, href }) => (
                  <li key={href}>
                    <Link
                      href={href}
                      className="text-gray-700 hover:text-brand-dark"
                      onClick={toggleMenu}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};
