"use client";
import React, { useEffect } from "react";
const LOCAL_STORAGE_MODAL_KEY = "isPatologyModalOpen";
const CTAButton: React.FC = () => {
  const openModal = () => {
    localStorage.setItem(LOCAL_STORAGE_MODAL_KEY, "true");
    window.dispatchEvent(new Event("storage"));
  };
  useEffect(() => {
    const scrollToHash = () => {
      if (typeof window !== "undefined") {
        const hash = window.location.hash;
        if (hash) {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    };

    scrollToHash();
  }, []);
  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="mt-8 flex items-center justify-center rounded-full border border-gray-950 bg-[#F7DF37] px-4 py-2 text-base font-normal text-gray-900 transition-transform hover:scale-105"
      >
        Falar com médico
      </button>
    </>
  );
};

export default CTAButton;
