"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  allPatologies: string[];
  selectedPatologies: string[];
  onUpdatePatologies: (updated: string[]) => void;
}

interface Payload {
  name: string;
  phone?: string;
  patologies: string[];
  data?: {
    referrer?: string;
    userAgent?: string;
    language?: string;
    appVersion?: string;
    platform?: string;
    src?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    utm_campaign?: string;
    utm_term?: string;
    [key: string]: string | undefined;
  };
}

const LOCAL_STORAGE_KEY = "selectedPatologies";

const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  allPatologies,
  selectedPatologies,
  onUpdatePatologies,
}) => {
  const [name, setName] = useState("");
  const [currentPatologies, setCurrentPatologies] =
    useState<string[]>(selectedPatologies);
  const searchParams = useSearchParams();
  const utmParams = {
    src: searchParams.get("src"),
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_content: searchParams.get("utm_content"),
    utm_campaign: searchParams.get("utm_campaign"),
    utm_term: searchParams.get("utm_term"),
  };

  const validUtmParams = Object.fromEntries(
    Object.entries(utmParams).filter(([_, value]) => value !== null)
  ) as { [key: string]: string };

  useEffect(() => {
    setCurrentPatologies(selectedPatologies);
  }, [selectedPatologies]);

  const handleSelectPatology = (patology: string) => {
    setCurrentPatologies((prev) => {
      const updatedSelection = prev.includes(patology)
        ? prev.filter((p) => p !== patology) // Remove se já estiver selecionado
        : [...prev, patology]; // Adiciona se não estiver selecionado

      // Atualiza no localStorage e na função de callback
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSelection));
      onUpdatePatologies(updatedSelection);

      return updatedSelection;
    });
  };

  function removeEmptyFields(obj: any): any {
    if (Array.isArray(obj)) {
      return obj
        .map((value) =>
          value && typeof value === "object" ? removeEmptyFields(value) : value
        )
        .filter((value) => value !== null && value !== "");
    } else if (typeof obj === "object" && obj !== null) {
      return Object.entries(obj).reduce((accumulator, [key, value]) => {
        if (value !== null && value !== "") {
          if (typeof value === "object") {
            const cleanedValue = removeEmptyFields(value);
            if (Object.keys(cleanedValue).length !== 0) {
              accumulator[key] = cleanedValue;
            }
          } else {
            accumulator[key] = value;
          }
        }
        return accumulator;
      }, {} as any);
    }
    return obj;
  }

  const handleSubmit = async () => {
    sendGTMEvent({
      event: "buttonWhatsappClicked",
      value: "Falar com o médico",
    });

    // Formatar mensagem para WhatsApp
    const patologiesText = currentPatologies
      .map((p, i) => `${i + 1}. ${p}`)
      .join("%0A");
    const message = `Olá, me chamo ${encodeURIComponent(
      name
    )}.%0A%0APatologias selecionadas:%0A${patologiesText}`;
    const whatsappURL = `https://api.whatsapp.com/send?phone=5521993686082&text=${message}`;

    let payload: Payload = {
      name,
      patologies: { ...selectedPatologies },
      data: {
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        language: navigator.language,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        ...validUtmParams,
      },
    };

    payload = removeEmptyFields(payload);

    try {
      const response = await fetch("/api/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        console.error("Falha ao enviar dados para a API");
      }
    } catch (error) {
      alert(error);
    } finally {
      window.open(whatsappURL, "_self");
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="w-full max-w-md rounded-lg bg-white p-4 shadow-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
          >
            <Image
              src="/image/Banner-Depoimentos-Google.png"
              alt="Icone consulta"
              width={390}
              height={180}
            />

            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-4 mt-8 w-full rounded-md border text-black border-black p-2"
              placeholder="Digite seu nome"
            />

            <div className="mb-4 mt-4">
              {selectedPatologies.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {selectedPatologies.map((patology) => (
                    <span
                      key={patology}
                      className="inline-block rounded-md bg-brand-dark px-2 py-1 text-xs text-white"
                    >
                      {patology}
                    </span>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-gray-500">
                  Nenhuma patologia selecionada.
                </p>
              )}
            </div>

            {/* Exibição das patologias organizadas */}
            <div className="patologies-container mb-4 h-36 overflow-y-scroll rounded-xl border border-gray-100 px-3">
              {[
                ...allPatologies.filter((patology) =>
                  currentPatologies.includes(patology)
                ),
                ...allPatologies.filter(
                  (patology) => !currentPatologies.includes(patology)
                ),
              ].map((patology) => (
                <div
                  key={patology}
                  className={`patology-item flex items-center justify-between p-2 cursor-pointer ${
                    currentPatologies.includes(patology)
                      ? "text-black font-bold"
                      : "bg-white text-black"
                  }`}
                  onClick={() => handleSelectPatology(patology)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleSelectPatology(patology)
                  }
                >
                  <span className="patology-name">{patology}</span>
                  <div className="circle w-4 h-4 flex items-center justify-center border rounded-full">
                    {currentPatologies.includes(patology) && (
                      <div className="circle-inner w-2 h-2 bg-green-500 rounded-full"></div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <button
              type="button"
              onClick={handleSubmit}
              className={`w-full rounded-md bg-brand px-4 py-2 text-white ${
                name.trim() && selectedPatologies.length > 0
                  ? "hover:bg-brand-dark"
                  : "opacity-50 cursor-not-allowed"
              }`}
              disabled={!(name.trim() && selectedPatologies.length > 0)}
            >
              Falar com médico
            </button>

            <button
              type="button"
              onClick={onClose}
              className="w-full rounded-md px-4 mt-2 py-2 text-black"
            >
              Voltar
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
