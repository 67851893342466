"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import Image from "next/image";

const videos = [
  {
    id: "video1",
    title: "Cannabis medicinal vicia?",
    thumbnail: "/image/thumb-mitos.webp",
    videoUrl: "https://player.vimeo.com/video/1011482748?autoplay=1",
  },
  {
    id: "video2",
    title: "Cannabis medicinal causa euforia?",
    thumbnail: "/image/thumb-mitos.webp",
    videoUrl: "https://player.vimeo.com/video/1011482530?autoplay=1",
  },
];

const VideoGallery = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  return (
    <div className="grid w-full max-w-[800px] cursor-pointer grid-cols-1 gap-16 p-4 md:grid-cols-2 md:gap-4">
      {videos.map((video) => (
        <div
          key={video.id}
          className="relative flex w-full flex-col items-center"
          onClick={() => setSelectedVideo(video.videoUrl)}
        >
          <Image
            src={video.thumbnail}
            alt={video.title}
            className="rounded-2xl"
            width={1080}
            height={1920}
          />
          <motion.div
            className="left-30 absolute top-48 z-20 flex h-16 w-16 items-center justify-center rounded-full bg-[#F7D805] pl-1"
            style={{ transform: "translate(-50%, -50%)" }}
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 1.5, repeat: Number.POSITIVE_INFINITY }}
          >
            <Image
              src="/image/play-icon.svg"
              alt="Play Icon"
              width={26}
              height={30}
            />
          </motion.div>
          <span className="pb-2 pt-4 text-center text-lg leading-relaxed text-white">
            {video.title}
          </span>
        </div>
      ))}

      {/* Modal de vídeo */}
      {selectedVideo && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <div className="relative w-[90%] max-w-[800px] h-[60vh] md:h-[80vh] bg-black rounded-lg">
            <button
              className="absolute top-2 right-2 text-white text-xl bg-gray-800 rounded-full px-3 py-1 hover:bg-gray-600"
              onClick={() => setSelectedVideo(null)}
            >
              ✕
            </button>
            <iframe
              className="w-full h-full rounded-2xl"
              src={selectedVideo}
              allow="fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="Vídeo"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoGallery;
