"use client";

import { motion } from "framer-motion";

const LOCAL_STORAGE_MODAL_KEY = "isPatologyModalOpen";
const ActionButtons: React.FC = () => {
  const openModal = () => {
    localStorage.setItem(LOCAL_STORAGE_MODAL_KEY, "true");
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <>
      <div className="flex flex-row gap-4">
        <motion.button
          className="rounded-xl bg-[#F7DF37] px-4 py-2 text-black"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => openModal()}
        >
          Solicite a sua
        </motion.button>

        <motion.button
          className="rounded-xl border border-white px-4 py-2 text-white"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => openModal()}
        >
          Quero ser paciente
        </motion.button>
      </div>
    </>
  );
};

export default ActionButtons;
