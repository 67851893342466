"use client";

import ReactDOM from "react-dom";

export function PreloadResources() {
  ReactDOM.preconnect("https://connect.facebook.net");
  ReactDOM.prefetchDNS("//connect.facebook.net");
  // ReactDOM.preconnect("https://www.googletagservices.com");
  // ReactDOM.preconnect("https://www.google-analytics.com");
  // ReactDOM.preconnect("https://www.googletagmanager.com");
  // ReactDOM.prefetchDNS("//www.googletagservices.com");
  // ReactDOM.prefetchDNS("//www.google-analytics.com");
  // ReactDOM.prefetchDNS("//www.googletagmanager.com");
  // ReactDOM.prefetchDNS("https://www.googletagmanager.com/gtm.js");
  return null;
}
