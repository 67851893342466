"use client";

import { useEffect, useState, CSSProperties } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "../../_components/popupContato";

const LOCAL_STORAGE_PAT_KEY = "selectedPatologies";
const LOCAL_STORAGE_MODAL_KEY = "isPatologyModalOpen";

const patologies = [
  "Alcoolismo",
  "Alzheimer",
  "Ansiedade",
  "Bipolaridade",
  "Diabetes",
  "Dores",
  "Anorexia",
  "Autismo",
  "Crohn",
  "Depressão",
  "Epilepsia",
  "Enxaqueca",
  "Fibromialgia",
  "Insônia",
  "Parkinson",
  "Tabagismo",
  "Glaucoma",
  "TDAH",
  "Esclerose múltipla",
  "Intestino irritável",
];

type PatologyFormProps = {
  sx?: { container?: CSSProperties };
  showButton?: boolean;
};

const PatologyForm: React.FC<PatologyFormProps> = ({
  sx,
  showButton = true,
}) => {
  const [selectedPatologies, setSelectedPatologies] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 🔹 Carrega os dados do localStorage ao iniciar
  useEffect(() => {
    const savedPatologies = localStorage.getItem(LOCAL_STORAGE_PAT_KEY);
    if (savedPatologies) {
      setSelectedPatologies(JSON.parse(savedPatologies));
    }

    const savedModalState = localStorage.getItem(LOCAL_STORAGE_MODAL_KEY);
    setIsModalOpen(savedModalState === "true");
  }, []);

  // 🔹 Escuta mudanças no localStorage
  useEffect(() => {
    const checkLocalStorage = () => {
      const savedModalState = localStorage.getItem(LOCAL_STORAGE_MODAL_KEY);
      setIsModalOpen(savedModalState === "true");
    };

    // Verifica mudanças no localStorage a cada render
    checkLocalStorage();

    // Configura um interval para checar mudanças a cada 500ms
    const interval = setInterval(checkLocalStorage, 500);

    return () => clearInterval(interval);
  }, []);

  // 🔹 Atualiza a lista de patologias no localStorage
  const handleSelectPatology = (patology: string) => {
    setSelectedPatologies((prev) => {
      const updatedSelection = prev.includes(patology)
        ? prev.filter((p) => p !== patology) // Remove se já estiver selecionado
        : [...prev, patology]; // Adiciona se não estiver selecionado

      localStorage.setItem(
        LOCAL_STORAGE_PAT_KEY,
        JSON.stringify(updatedSelection)
      );

      return updatedSelection;
    });
  };

  // 🔹 Abre o modal e atualiza o localStorage
  const openModal = () => {
    localStorage.setItem(LOCAL_STORAGE_MODAL_KEY, "true");
    setIsModalOpen(true);
  };

  // 🔹 Fecha o modal e atualiza o localStorage
  const closeModal = () => {
    localStorage.setItem(LOCAL_STORAGE_MODAL_KEY, "false");
    setIsModalOpen(false);
  };

  return (
    <>
      {/* 🔹 Botão de "Falar com Médico" aparece se houver ao menos 1 patologia selecionada */}
      {selectedPatologies.length > 0 && showButton && (
        <div className="fixed bottom-4 right-4 z-40">
          <button
            type="button"
            onClick={openModal}
            className="rounded-md bg-blue-500 px-4 py-2 text-white shadow-lg"
          >
            Falar com médico
          </button>
        </div>
      )}

      {/* 🔹 Modal de consulta */}
      {isModalOpen && (
        <Modal
          isVisible={isModalOpen}
          onClose={closeModal}
          allPatologies={patologies}
          selectedPatologies={selectedPatologies}
          onUpdatePatologies={(updatedPatologies) => {
            setSelectedPatologies(updatedPatologies);
            localStorage.setItem(
              LOCAL_STORAGE_PAT_KEY,
              JSON.stringify(updatedPatologies)
            );
          }}
        />
      )}

      {/* 🔹 Lista de Patologias */}
      <div
        style={sx?.container}
        className="grid grid-cols-2 gap-4 md:grid-cols-4"
      >
        {patologies.map((patology) => (
          <motion.label
            key={patology}
            className={`cursor-pointer flex items-center justify-between rounded-full border px-4 py-2 ${
              selectedPatologies.includes(patology)
                ? "bg-brand text-white"
                : "bg-white text-black"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleSelectPatology(patology)}
          >
            {patology}

            <motion.div
              className={`mr-2 flex min-h-4 max-h-4 max-w-4 min-w-4 items-center justify-center rounded-full border ${
                selectedPatologies.includes(patology)
                  ? "border-white bg-white"
                  : "border-gray-500"
              }`}
              layout
            >
              <AnimatePresence>
                {selectedPatologies.includes(patology) && (
                  <motion.div
                    className="h-1 w-1 rounded-full bg-brand"
                    layout
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ duration: 0.3 }}
                  />
                )}
              </AnimatePresence>
            </motion.div>
            <input
              type="checkbox"
              name="patology"
              value={patology}
              checked={selectedPatologies.includes(patology)}
              onChange={() => handleSelectPatology(patology)}
              className="hidden"
            />
          </motion.label>
        ))}
      </div>
    </>
  );
};

export default PatologyForm;
